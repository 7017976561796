body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
* {font-size: 14px;}

.tab-content{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  border-color: #dee2e6;
  /*border-style: dotted;*/
}